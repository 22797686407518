<template>
  <div class="mb-5">
    <AddTransaction v-if="shouldDisplayAddTransactionButton" />
    <AddRefund v-if="shouldDisplayRefund" />
  </div>
  <div class="table-container position-relative">
    <div
      v-if="isLoading"
      class="loader-overlay"
    >
      <div
        class="loader"
      />
      <p
        class="loading-text"
      >
        Loading transactions...
      </p>
    </div>
    <table class="table is-size-7 is-vcentered is-hoverable is-fullwidth blocked-ip-address-list">
      <OrderableTableHeader
        :accounting-user="isAccountingUser()"
      />
      <tbody v-if="transactions.length > 0">
        <TransactionRow
          v-for="(transaction, index) in transactions"
          :key="index"
          :transaction="transaction"
          :edit="editTransaction"
          :accounting-user="isAccountingUser()"
        />
      </tbody>
      <tbody v-else>
        <tr>
          <td
            class="has-text-centered is-size-7"
            colspan="100"
          >
            No transactions found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Pagination
    v-if="!isPaginationDisable && maxPage > 1"
    :max-page="maxPage"
  />
  <SelectMerchant
    v-if="isOpenModal"
    :transaction="editedTransaction"
    :merchant="merchant"
    :open-edit="openEdit"
    @closeModal="closeModal"
  />
  <EditTransaction
    v-if="isEditOpen"
    :transaction="editedTransaction"
    :merchant-id="selectedMerchantId"
    @closeModal="closeModal"
  />
  <div class="is-flex is-justify-content-space-between">
    <DownloadCsvButton :disabled="transactions.length === 0" />
  </div>
</template>

<script>
import { inject, ref, watch } from 'vue';

import initializeFlagsmith from '../flagsmith';
import OrderableTableHeader from './OrderableTableHeader.vue';
import TransactionRow from './TransactionRow.vue';
import EditTransaction from './EditTransaction.vue';
import SelectMerchant from './SelectMerchant.vue';
import DownloadCsvButton from './DownloadCsvButton.vue';
import AddTransaction from './AddTransaction.vue';
import Pagination from './Pagination.vue';
import AddRefund from './AddRefund.vue';

export default {
    components: {
        OrderableTableHeader,
        TransactionRow,
        EditTransaction,
        SelectMerchant,
        DownloadCsvButton,
        AddTransaction,
        Pagination,
        AddRefund,
    },

    async setup() {
        const isOpenModal = ref(false);
        const editedTransaction = ref([]);
        const isEditOpen = ref(false);
        const selectedMerchantId = ref('');
        const merchant = ref('');

        const {
            transactions: {
                maxPage,
                transactions,
                fetchTransactions,
                isAccountingUser,
                isLoading,
            },
        } = inject('btb_transactions');

        const { filters } = inject('btb_transactions');
        const previousValues = ref({});

        watch(filters, (newVal) => {
            const currentValues = JSON.parse(JSON.stringify(newVal));
            const oldValues = previousValues.value;
            const mainFields = ['bankName', 'accountNumber', 'merchantId', 'currency'];
            const mainFieldChanged = mainFields.some(
                (field) => currentValues[field] !== oldValues[field],
            );

            if (mainFieldChanged) {
                const otherFieldsChanged = Object.keys(currentValues).some(
                    (key) => !mainFields.includes(key) && currentValues[key] !== oldValues[key],
                );

                if (!otherFieldsChanged) {
                    previousValues.value = currentValues;
                    isLoading.value = false;
                    return;
                }
            }
            isLoading.value = true;
            previousValues.value = currentValues;
        }, { deep: true, flush: 'post' });

        const flagsmith = await initializeFlagsmith();

        const shouldDisplayAddTransactionButton = flagsmith.hasFeature('btb_add_form');
        const isMockTransactionsDisable = flagsmith.hasFeature('use_btb_mock_transactions');
        const isPaginationDisable = !flagsmith.hasFeature('btb_pagination');
        const shouldDisplayRefund = flagsmith.hasFeature('btb_refund_form');

        if (!isMockTransactionsDisable) {
            fetchTransactions();
        }

        const editTransaction = (transaction) => {
            if (isOpenModal.value === false) {
                editedTransaction.value = transaction;
                const merchantName = transaction.merchantId.split(' ')[0];
                const merchantId = flagsmith
                    .getValue('merchants_list')
                    .split(' ')
                    .filter((element) => element.endsWith(merchantName))[0]
                    .split(',')[0];
                merchant.value = merchantId;
                isOpenModal.value = true;
            }
        };

        const openEdit = (transaction, merchantId) => {
            if (isEditOpen.value === false) {
                editedTransaction.value = transaction;
                selectedMerchantId.value = merchantId;
                isEditOpen.value = true;
            }
        };

        const closeModal = () => {
            isOpenModal.value = false;
            isEditOpen.value = false;
        };
        return {
            transactions,
            isEditOpen,
            editTransaction,
            openEdit,
            isPaginationDisable,
            maxPage,
            closeModal,
            isOpenModal,
            editedTransaction,
            shouldDisplayAddTransactionButton,
            shouldDisplayRefund,
            merchant,
            selectedMerchantId,
            isAccountingUser,
            isLoading,
        };
    },
};
</script>
